import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { TrashCan as Delete, CloudUpload, ArrowRight, ArrowLeft, DocumentBlank } from '@carbon/icons-react';
import { Button, FileUploaderButton, FileUploaderItem, RadioButton, Table, TableHead, TableBody, TableRow, TableHeader, TableCell } from 'carbon-components-react';
import { useSelector, useDispatch } from 'react-redux';
import DeleteConfirmation from '../../../common/DeleteConfirmation';
import useToastAlert from '../../../../hooks/useToastAlert'
import { __trans, getData, makeRequest } from '../../../../_helpers';

var lastId = 0;

const UploadDocument = ({ currentstep, handleNextPrevoius, requestdata }) => {
	const { addError } = useToastAlert();
	const { reloaddata } = useSelector((state) => state.common);
	const dispatch = useDispatch();
	const [deletedocument, setDeleteDocument] = useState(false);
	const [template, setTemplate] = useState({});
	const [docData, setDocData] = useState({});
	const [radioerror, setRadioError] = useState('');
	const [fileerror, setFileError] = useState('');
	const [itemid, setItemId] = useState('');
	const [uploadedDoc, setUploadedDoc] = useState({});
	const [previewfiles, setPreviewFiles] = useState([]);
	const [uploaddata, setUploadData] = useState([]);

	const getDocumentList = async () => {
		await getData("template/get-client-template-document/" + requestdata._id, {}, 'POST', addError, dispatch)
			.then((res) => {
				console.log('res', res)
				if (res.type === "success") {
					setDocData(res.documents[0].documents)
					setUploadedDoc(res.uploaded)
					setTemplate(res.template)
				}
			});
	}

	useEffect(() => {
		if (currentstep === 2) {
			getDocumentList();
		}
	}, [currentstep])

	useEffect(() => {
		if (reloaddata) {
			getDocumentList();
			setItemId('')
		}
	}, [reloaddata])

	console.log(docData,"docData")

	const handleDeleteDocument = (id) => {
		setItemId(id);
		setDeleteDocument(true);
	}

	const uid = (prefix = 'id') => {
		lastId++;
		return `${prefix}${lastId}`;
	}

	const handleFileChange = async (event) => {
		//clear previous selected file
		clearPreviousFiles();
		const files_object = Array.from(event.target.files); // Convert FileList to Array
		var new_upload_data = [...uploaddata];
		var new_files = [...previewfiles];

		var file = files_object;
		// Iterate through selected files
		for (const file of files_object) {
			console.log(file, "file");

			// Uncomment and adjust validation logic as needed
			// if (file.size > 10 * 1000 * 1024) {
			//   setFileError(__trans('error_msg.file_size_limit_error'));
			//   return false;
			// }

			// if (!isValidFileUploaded(file)) {
			//   setFileError(__trans('error_msg.select_valid_file_type_error'));
			//   return false;
			// }

			// Update upload data
			new_upload_data.push(file);

			// Add file preview data
			new_files.push({
				uuid: uid(), // Function to generate unique ID
				name: file.name,
				filesize: file.size,
				status: 'edit',
				iconDescription: 'Delete file',
				invalidFileType: false // Set this based on your validation logic
			});
		}

		setFileError('');
		setUploadData(new_upload_data)
		setPreviewFiles(new_files);
	};

	const checkPrevoiusUploadedFile = async (event) => {

		if (event.target.value !== "") {
			event.preventDefault();
			this.form.validateFields(event.target.name);

			const fileObject = event.target.id;
			const name = event.target.files[0].name;
			const size = ((event.target.files[0].size) / 1000000).toFixed(2);
			let img = [...this.state.img];

			img.push({ inptid: fileObject, name: name, size: size });

			const fetchImage = img.map(e => e['inptid'])
				.map((e, i, final) => final.lastIndexOf(e) === i && i)
				.filter(e => img[e]).map(e => img[e]);

			this.setState({ img: fetchImage });

		} else {
			return false;
		}
	}

	const clearPreviousFiles = () => {
		setFileError('');
		setRadioError('');
		setItemId('');
		setUploadData([])
		setPreviewFiles([]);
	}

	const isValidFileUploaded = (file) => {
		const validExtensions = ['jpg', 'png', 'pdf'];
		const fileExtension = file.name.split('.').pop();
		return validExtensions.includes(fileExtension);
	}

	const handleRemoveFile = (index) => {
		// Remove the file from the upload files array
		const uploadFiles = [...uploaddata];
		uploadFiles.splice(index, 1);
		setUploadData(uploadFiles);
		// Remove the file from the preview files array
		const previewFiles = [...previewfiles];
		previewFiles.splice(index, 1);
		setPreviewFiles(previewFiles);
	};

	const handleRadioChanged = (e) => {
		console.log(e,"ItemId-----")
		setItemId(e);
		setRadioError('');
	}

	const validateForm = () => {
		let validateData = true;
		if (itemid === '') {
			setRadioError(__trans('error_msg.select_radio_for_refrence_file'));
			validateData = false;
		} else {
			setRadioError('');
		}
		if (uploaddata.length === 0) {
			setFileError(__trans('error_msg.select_valid_file_error'));
			validateData = false;
		} else {
			setFileError('');
		}

		return validateData;
	}

	const handleSubmit = (e) => {
		//Before submit validate form
		if (validateForm()) {
			const formData = new FormData();
			if (uploaddata.length > 0) {
				uploaddata.forEach(item => { formData.append(`document`, item) });
			}
			formData.append(`request_id`, requestdata._id)
			formData.append(`template_id`, template._id)
			formData.append(`document_id`, itemid)
			submitForm(formData)
		}
	}

	const submitForm = async (formData) => {
		var submit_url = 'document/upload';
		await makeRequest(submit_url, formData, 'POST', addError)
			.then((res) => {
				if (res.type === "success") {
					clearPreviousFiles();
					getDocumentList();
				}
			}).catch((error) => {
				addError('error', error.message)
			});
	}


	return (
		<>
			{/* Step 3 START  */}
			<div className='container mb-5'>
				<div className='row mb-4'>
					<div className='col cstm-top-heading pt-4'>
						<h6>{__trans('account_setup_step3_info')}</h6>
					</div>
					<div className='col d-flex justify-content-end'>
						<div className='requestedFilesCompleted p-4 d-flex align-items-center gap-2'>
							<DocumentBlank width={'25px'} height={'25px'} color='#707BBD' />
							<h3>{uploadedDoc.length}/{docData.length}</h3>
							<p>{__trans('requested_files_completed')}</p>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col'>
						<div className="">
							<div className='cloud-file-uploader d-flex align-items-center justify-content-center mb-3'>
								<div className='align-content-center d-flex flex-column justify-content-center'>
									<CloudUpload className='mx-auto mb-3' width={'85px'} height={'85px'} color='#4451A3' />
									<p className="cds--label-description" id="description" dir="auto">{__trans('client_upload_file_info')}</p>
									<div className="text-center mb-1">
										<FileUploaderButton className="btn--primary mb-2"
											labelText={__trans('choose_file_button')}
											disableLabelChanges={true}
											buttonKind="primary"
											size="md"
											type="file"
											webkitdirectory="true" // Allow folder uploads
											accept={['.jpg', '.png', '.pdf']}
											multiple={true}
											onChange={handleFileChange}
											style={{ paddingInlineEnd: '18px', }}
										/>

									</div>
									<div className="text-center mb-1">
										{fileerror !== '' ? <span className="text-danger mt-1">{fileerror}</span> : ''}
									</div>
									<div className="cds--file-container cds--file-container--drop fullWidth">
										{previewfiles.length > 0 && previewfiles.map((file, index) => (
											<FileUploaderItem
												className="bg-body-secondary"
												key={uid()}
												uuid={file.uuid}
												name={file.name}
												filesize={file.filesize}
												errorSubject={__trans('error_msg.file_size_limit_error')}
												errorBody=""
												webkitdirectory="true" // Allow folder uploads
												// eslint-disable-next-line react/prop-types
												size="md"
												status={file.status}
												iconDescription={file.iconDescription}
												invalid={file.invalid}
												onDelete={() => handleRemoveFile(index)}
											/>
										))}
									</div>
								</div>
							</div>
							<div className="text-center">
								<p className="text-center mb-3"><strong>Note:</strong>{__trans('select_file_for_info')}</p>
								<Button kind="primary" className="btn--primary" renderIcon={CloudUpload} size='md' onClick={handleSubmit}>Upload</Button>
							</div>
						</div>
					</div>

					<div className="col">
						<div className='row'>
							{/* Client Requested Document*/}
							<div className='col-md-12 mb-4'>
								<h5 className="mb-2">{__trans('client_setup_doc_list_title')}</h5>
								{radioerror !== '' ? <span className="text-danger">{radioerror}</span> : ''}
								<Table aria-label="sample table" size="sm">
									<TableHead>
										<TableRow>
											<TableHeader>

											</TableHeader>
											<TableHeader>
												{__trans('client_setup_doc_table_header.file_name')}
											</TableHeader>
											<TableHeader>
												{__trans('client_setup_doc_table_header.description')}
											</TableHeader>
											<TableHeader>
												{__trans('client_setup_doc_table_header.created_at')}
											</TableHeader>
										</TableRow>
									</TableHead>
									<TableBody>
										{docData.length && docData.map((item, index) => (
											<TableRow key={item._id}>
												<TableCell>
													<RadioButton checked={itemid === item._id ? true : false} value={item._id} name="radio-button_demo" labelText="" onChange={(e) => handleRadioChanged(e)} />
												</TableCell>
												<TableCell>
													{item.name}
												</TableCell>
												<TableCell>
													{item.description}
												</TableCell>
												<TableCell>
													{moment(item.updated_at).format('MM/DD/YYYY')}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
							{/*Client Uploaded Document*/}
							{uploadedDoc.length > 0 ?
								<div className='col-md-12 '>
									<h5 className="mb-2">{__trans('client_setup_uploadeddoc_list_title')}</h5>
									<Table aria-label="sample table" size="sm">
										<TableHead>
											<TableRow>
												<TableHeader>
													{__trans('client_setup_uploadeddoc_table_header.file_name')}
												</TableHeader>
												<TableHeader>
													{__trans('client_setup_uploadeddoc_table_header.description')}
												</TableHeader>
												<TableHeader>
													{__trans('client_setup_uploadeddoc_table_header.created_at')}
												</TableHeader>
												<TableHeader>
													{__trans('client_setup_uploadeddoc_table_header.actions')}
												</TableHeader>
											</TableRow>
										</TableHead>
										<TableBody>
											{uploadedDoc.length && uploadedDoc.map((item, index) => (
												<TableRow key={item._id}>
													<TableCell>
														{item.original_file_name}
													</TableCell>
													<TableCell>
														{item.description}
													</TableCell>
													<TableCell>
														{moment(item.created_at).format('MM/DD/YYYY')}
													</TableCell>
													<TableCell>
														<Button size="sm" kind="ghost" renderIcon={Delete} onClick={() => handleDeleteDocument(item._id)} iconDescription={__trans('delete_button')} hasIconOnly />
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</div>
								: ''
							}
						</div>
					</div>

				</div>
			</div>
			{/* Delete Confirmation popup */}
			<DeleteConfirmation open={deletedocument} setOpen={setDeleteDocument} module='uploaded-document' id={itemid} />

			<div className='container'>
				<div className='my-3 text-end'>
					<Button kind="secondary" renderIcon={ArrowLeft} size='md' onClick={() => handleNextPrevoius(currentstep - 1)}>Previous</Button>
					<Button className="btn--primary" renderIcon={ArrowRight} size='md' onClick={() => handleNextPrevoius(currentstep + 1)}><span className='pe-3'>Next</span> </Button>
				</div>
			</div>
		</>
	);
};

export default UploadDocument;